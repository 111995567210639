import { Box, Typography } from '@mui/material'
import './AppFooter.scss'
import React from 'react'

const getYear = (): number => {
  return new Date().getFullYear()
}

const appVersion = process.env.REACT_APP_VERSION

const AppFooter = (): JSX.Element => {
  return (
    <Box sx={{ display: { sm: 'none', xs: 'none', md: 'block' } }} display={'flex'} alignContent={'center'} justifyContent={'space-around'} gridTemplateColumns={'auto'} className={'footer'}>
        <Box p={2}>
          <Typography className="text-muted">© {getYear()} FantaHockey</Typography>
          <Typography className="text-muted">Version: {appVersion}</Typography>
        </Box>
    </Box>
  )
}

AppFooter.displayName = 'AppFooter'
export default React.memo(AppFooter)

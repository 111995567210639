import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins, sans-serif',
          color: 'rgb(255, 255, 255)',
          borderRadius: '12px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins, sans-serif',
          borderRadius: '10px',
          textTransform: 'none',
          color: 'rgb(255, 255, 255)',
          boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          height: 0
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(19, 47, 76, 0.9)',
          backdropFilter: 'blur(5px)'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins, sans-serif',
          backgroundColor: 'rgba(9, 47, 85, 0.2)',
          color: 'rgb(255, 255, 255)',
          borderRadius: '8px',
          padding: '8px',
          '&.Mui-focused': {
            boxShadow: '0 0 4px rgba(9, 47, 85, 0.5)'
          }
        },
        input: {
          paddingTop: '24px',
          paddingBottom: '8px',
          color: 'rgb(255, 255, 255)',
          padding: '8px',
          '&::placeholder': {
            color: 'rgba(255, 255, 255, 0.5)' // Default placeholder color
          },
          '&.Mui-focused::placeholder': {
            color: '#5DADE2' // primary.info placeholder color on focus
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: 'rgba(255, 255, 255, 0.5)' // Default border color
          },
          '&:hover fieldset': {
            borderColor: 'rgba(255, 255, 255, 0.8)'
          },
          '&.Mui-focused fieldset': {
            borderColor: '#5DADE2', // primary.info border color on focus
            boxShadow: '0 0 4px rgba(9, 47, 85, 0.5)'
          }
        },
        input: {
          paddingTop: '24px',
          paddingBottom: '8px',
          color: 'rgb(255, 255, 255)',
          '&::placeholder': {
            color: 'rgba(255, 255, 255, 0.5)' // Default placeholder color
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(9, 47, 85, 0.1)',
          '&:hover': {
            backgroundColor: 'rgba(9, 47, 85, 0.2)'
          },
          '&.Mui-focused': {
            backgroundColor: 'rgba(9, 47, 85, 0.3)'
          }
        },
        input: {
          paddingTop: '24px',
          paddingBottom: '8px',
          color: 'rgb(255, 255, 255)',
          '&::placeholder': {
            color: 'rgba(255, 255, 255, 0.5)' // Default placeholder color
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#5DADE2', // info color for the thumb
          '&.Mui-checked': {
            color: '#5DADE2' // info color when checked
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#5DADE2' // info color for the track when checked
          }
        },
        track: {
          backgroundColor: 'rgba(255, 255, 255, 0.5)', // Default track color
          '&.Mui-checked': {
            backgroundColor: '#5DADE2' // info color for the track when checked
          }
        }
      }
    }
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    allVariants: {
      color: 'rgb(235, 237, 239)' // Global text color for all typography
    },
    h1: {
      fontWeight: 700,
      fontSize: '2.5rem'
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400
    }
  },
  palette: {
    text: {
      primary: 'rgb(235, 237, 239)',
      disabled: 'rgb(130, 130, 130)',
      secondary: 'rgb(255, 255, 255)'
    },
    primary: {
      light: 'rgb(39, 97, 158)',
      main: 'rgb(9, 47, 85)',
      dark: 'rgb(5, 35, 66)',
      contrastText: 'rgb(255,255,255)'
    },
    secondary: {
      main: 'rgb(10, 25, 41)',
      contrastText: 'rgb(240, 240, 240)'
    },
    info: {
      main: '#5DADE2',
      contrastText: 'rgb(255,255,255)'
    },
    background: {
      paper: 'rgb(19, 47, 76)',
      default: 'linear-gradient(135deg, rgba(10,25,41,1) 0%, rgba(9,47,85,1) 100%)'
    },
    action: {
      hover: 'rgba(9, 47, 85, 0.08)',
      selected: 'rgba(9, 47, 85, 0.16)'
    }
  },
  shape: {
    borderRadius: 8
  }
})

export default theme

import React from 'react'
import { Avatar, Box, Button, Divider, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Skeleton, styled, Typography } from '@mui/material'
import { useDocumentTitle } from '@uidotdev/usehooks'
import CenterBox from 'components/helpers/CenterBox'
import { type ConnectedYahooFantasyPlayer, type ConnectedYahooFantasyTeam } from 'features/fantasy-team/models/yahooConnectedTeam'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import { useNavigate } from 'react-router'
import PageBase from 'components/page-title/PageBase'
import { PlayerImage } from 'components/shared-components/PlayerImage'
import PlayerFantasyScore from './PlayerFantasyScore'
import MyFantasyPlayerStatistics from './MyFantasyPlayerStatistics'
import { useQuery } from '@tanstack/react-query'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import { useSnackbar } from 'hooks/notification/UseSnackbar'

const DisabledPlug = styled(ElectricalServicesIcon)({
  color: 'info',
  fontSize: 'calc(1dvw + 64px)'
})

const MyFantasyTeam: React.FC = React.memo((): JSX.Element => {
  useDocumentTitle('FantaHockey | My Fantasy Team')
  const { openSnackbar } = useSnackbar()
  const [isPlayerStatsDrawerOpen, setIsPlayerStatsDrawerOpen] = React.useState<boolean>(false)
  const [maxPlayerFantasyScore, setMaxPlayerFantasyScore] = React.useState<number>(0)
  const [minPlayerFantasyScore, setMinPlayerFantasyScore] = React.useState<number>(0)
  const [fantasyTeam, setFantasyTeam] = React.useState<ConnectedYahooFantasyTeam>({ players: [], goalies: [] })
  const [selectedPlayer, setSelectedPlayer] = React.useState<ConnectedYahooFantasyPlayer>({
    playerId: 0,
    name: '',
    playerHeadshotUrl: '',
    team: '',
    teamLogoUrl: '',
    position: '',
    fantasyScore: 0
  } satisfies ConnectedYahooFantasyPlayer)
  const navigate = useNavigate()

  const { data: isYahooUserConnected } = useQuery<boolean>({
    queryKey: [ApiQueryKeys.IsUserConnectedToYahooQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.IsUserConnectedToYahooQuery]()).data
    },
    initialData: false
  })

  const { isError: isErrorFetchingTeam, isFetching: isFetchingYahooTeam, data: yahooFantasyTeam } = useQuery<ConnectedYahooFantasyTeam>({
    queryKey: [ApiQueryKeys.GetConnectedYahooFantasyTeamQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetConnectedYahooFantasyTeamQuery]()).data
    },
    initialData: { players: [], goalies: [] }
  })

  React.useEffect(() => {
    const getFantasyTeam = async (): Promise<void> => {
      try {
        if (yahooFantasyTeam !== undefined) {
          setFantasyTeam(yahooFantasyTeam)
          setMaxPlayerFantasyScore(getMaxPlayerFantasyScore([...yahooFantasyTeam.players, ...yahooFantasyTeam.goalies]))
          setMinPlayerFantasyScore(getMinPlayerFantasyScore([...yahooFantasyTeam.players, ...yahooFantasyTeam.goalies]))
        }
      } catch (error: unknown) {
        openSnackbar('We can not fetch your Yahoo! Fantasy Team at this time.', 'error')
      }
    }
    void getFantasyTeam()
  }, [yahooFantasyTeam])

  const mapPosition = (position: string): string => {
    switch (position) {
      case 'C':
        return 'Center'
      case 'L':
        return 'Left Wing'
      case 'R':
        return 'Right Wing'
      case 'D':
        return 'Defense'
      case 'G':
        return 'Goalie'
      default:
        return ''
    }
  }

  const MyFantasyLoadingListItem = (): JSX.Element => (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Skeleton variant="circular" width={50} height={50} />
      </ListItemAvatar>
      <Box ml={1} />
      <ListItemText
        primary={
          <>
            <Skeleton variant='text' width='100%' height={20} />
            <Skeleton variant='text' width='100%' height={20} />
            <Box my={1} />
            <Skeleton variant="rectangular" width='100%' height={50} />
          </>
        }
      />
    </ListItem>
  )

  const MyFantasyTeamLoader = (): JSX.Element => (
    <List sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 2, m: 2 }}>
      <Box p={2}>
        {Array.from(Array(3).keys()).map((value, index) => (
          <MyFantasyLoadingListItem key={index} />
        ))}
      </Box>
    </List>
  )

  const getMaxPlayerFantasyScore = React.useCallback((players: ConnectedYahooFantasyPlayer[] | undefined): number => {
    return Math.max(...players?.map(player => player.fantasyScore) ?? [])
  }, [])

  const getMinPlayerFantasyScore = React.useCallback((players: ConnectedYahooFantasyPlayer[] | undefined): number => {
    return Math.min(...players?.map(goalie => goalie.fantasyScore) ?? [])
  }, [])

  const togglePlayerStatsDrawer = (newOpen: boolean): void => {
    setIsPlayerStatsDrawerOpen(newOpen)
  }
  return (
    <>
      <PageBase title='My Fantasy Team'>
        <Box mt={6} />
        {isFetchingYahooTeam && (
          <MyFantasyTeamLoader />
        )}
        {!isFetchingYahooTeam && isErrorFetchingTeam && (
          <Grid width={'100%'} item xs={12} sm={12} md={12} xl={12}>
            <CenterBox flexDirection={'column'}>
              <Typography m={4} variant='subtitle1'>
                We can not fetch your Yahoo! Fantasy Team at this time.
              </Typography>
              <DisabledPlug color='info' fontSize={'large'} />
              <Box my={2} />
              <Button variant='contained' color='success' onClick={() => { navigate('/home') }}>
                Go Home
              </Button>
            </CenterBox>
          </Grid>
        )}
        {!isFetchingYahooTeam && !isErrorFetchingTeam && !isYahooUserConnected && (
          <Grid width={'100%'} item xs={12} sm={12} md={12} xl={12}>
            <CenterBox flexDirection={'column'}>
              <Typography m={4} variant='subtitle1' sx={{ display: { sm: 'none', xs: 'none', md: 'block' } }}>
                You have not connected your Yahoo! Fantasy Team.
              </Typography>
              <Typography m={4} variant='subtitle1' sx={{ display: { sm: 'block', xs: 'block', md: 'none' } }}>
                You have not connected your Team.
              </Typography>
              <DisabledPlug color='info' fontSize={'large'} />
              <Box my={2} />
              <Button variant='contained' color='success' onClick={() => { navigate('/connect-my-fantasy-team') }}>
                Connect My Fantasy Team
              </Button>
            </CenterBox>
          </Grid>
        )}
        <MyFantasyPlayerStatistics
          onOpen={() => { togglePlayerStatsDrawer(true) }}
          open={isPlayerStatsDrawerOpen}
          player={selectedPlayer}
          onClose={() => { togglePlayerStatsDrawer(false) }}
        />
        { isYahooUserConnected && !isFetchingYahooTeam && fantasyTeam.players.length > 0 && (
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Typography fontFamily={'Spantaran'} ml={3} variant='h6'>Players</Typography>
            <Paper sx={{ m: 2 }} elevation={3}>
              <List sx={{ justifyContent: 'space-between', width: '100%', borderRadius: 2 }}>
                {fantasyTeam?.players.map((player, index: number) => (
                  <React.Fragment key={player.playerId}>
                    <ListItemButton onClick={() => { togglePlayerStatsDrawer(true); setSelectedPlayer(player) }}>
                      <ListItem>
                        <ListItemAvatar>
                          <PlayerImage src={player.playerHeadshotUrl} />
                        </ListItemAvatar>
                        <Box mr={2} />
                        <ListItemText primary={<Typography fontWeight={700}>{player.name}</Typography>}
                          secondary={<>
                            {mapPosition(player.position)}
                            <Box my={2} />
                          </>}
                        />
                        <PlayerFantasyScore
                          minFantasyScore={minPlayerFantasyScore}
                          fantasyScore={player.fantasyScore}
                          maxFantasyScore={maxPlayerFantasyScore}
                          teamLogoUrl={player.teamLogoUrl}
                        />
                      </ListItem>
                    </ListItemButton>
                    {fantasyTeam.players.length - 1 !== index && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Paper>

            <Grid item xs={12} sm={12} md={12} xl={12}>
              <Typography fontFamily={'Spantaran'} ml={3} variant='h6'>Goalies</Typography>
              <Paper sx={{ m: 2 }} elevation={3}>
                <List>
                  {fantasyTeam.goalies.map((goalie, index: number) => (
                    <React.Fragment key={goalie.playerId}>
                      <ListItem>
                        <ListItemButton onClick={() => { togglePlayerStatsDrawer(true); setSelectedPlayer(goalie) }}>
                          <Typography variant='h6'>{index + 1}</Typography>
                          <Box mr={1} />
                          <ListItemAvatar>
                            <Avatar sx={{ width: 'calc(1dvw + 64px)', height: 'calc(1dvw + 64px)' }}>
                              <PlayerImage src={goalie.playerHeadshotUrl} />
                            </Avatar>
                          </ListItemAvatar>
                          <Box mr={2} />
                          <ListItemText primary={<Typography fontWeight={700}>{goalie.name}</Typography>}
                            secondary={<>
                              {mapPosition(goalie.position)}
                              <Box my={2} />
                              <Box display='flex' flexDirection='row' alignItems={'center'} justifyContent={'center'}>
                              </Box>
                            </>} />
                          <PlayerFantasyScore
                            minFantasyScore={minPlayerFantasyScore}
                            fantasyScore={goalie.fantasyScore}
                            maxFantasyScore={maxPlayerFantasyScore}
                            teamLogoUrl={goalie.teamLogoUrl}
                          />
                        </ListItemButton>
                      </ListItem>
                      {fantasyTeam.goalies.length - 1 !== index && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              </Paper>
            </Grid>
          </Grid>
        )}
      </PageBase>
    </>
  )
})

MyFantasyTeam.displayName = 'MyFantasyTeam'
export default MyFantasyTeam

import * as React from 'react'
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'

import App from './App'
import './index.scss'

import reportWebVitals from './reportWebVitals'
import theme from './theme/AppTheme'
import Home from './components/home/Home'
import { ThemeProvider } from '@emotion/react'
import { FantaHockeyProvider } from 'providers/AuthProvider'
import { createRoot } from 'react-dom/client'
import AppLogin from 'components/app-login/AppLogin'
import { GoogleOAuthProvider } from '@react-oauth/google'
import LiveGameScores from 'components/live-game-scores/LiveGameScores'
import ConnectMyFantasyTeam from 'components/connect-my-fantasy-team/ConnectMyFantasyTeam'
import AuthenticatedRoute from 'components/authenticated-route/AuthenticatedRoute'
import MyFantasyTeam from 'components/my-fantasy-team/MyFantasyTeam'
import AppSettings from 'components/app-settings/AppSettings'
import { SnackbarProvider } from 'hooks/notification/UseSnackbar'
import MyProfile from 'components/my-profile/MyProfile'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const domNode = document.getElementById('root')
if (domNode !== null) {
  const root = createRoot(domNode)
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        experimental_prefetchInRender: true,
        retry: 0
      }
    }
  })

  root.render(
    <React.StrictMode>
      <BrowserRouter future={{ v7_startTransition: true }}>
      <QueryClientProvider client={queryClient}>
      <SnackbarProvider>
        <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
          <FantaHockeyProvider>
              <ThemeProvider theme={theme}>
                <Routes>
                  <Route path='/' element={<App />}>
                    <Route path='login' element={<AppLogin />} />
                    <Route element={<AuthenticatedRoute> <Home /> </AuthenticatedRoute>} path='home' />
                    <Route element={<AuthenticatedRoute> <LiveGameScores /> </AuthenticatedRoute>} path='live-scores' />
                    <Route element={<AuthenticatedRoute> <MyFantasyTeam /> </AuthenticatedRoute>} path='my-fantasy-team' />
                    <Route element={<AuthenticatedRoute> <ConnectMyFantasyTeam /> </AuthenticatedRoute>} path='connect-my-fantasy-team' />
                    <Route element={<AuthenticatedRoute> <AppSettings /> </AuthenticatedRoute>} path='settings' />
                    <Route element={<AuthenticatedRoute> <MyProfile /> </AuthenticatedRoute>} path='my-profile' />
                    <Route path='*' element={<Navigate to="home" />} />
                    <Route index element={<Navigate to="login" />} />
                  </Route>
                </Routes>
              </ThemeProvider>
          </FantaHockeyProvider>
        </GoogleOAuthProvider>
        </SnackbarProvider>
      </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  )
}
reportWebVitals()

/* eslint-disable @typescript-eslint/no-misused-promises */
import { Box, Button, Card, CardContent, CircularProgress, styled, Typography } from '@mui/material'
import React from 'react'
import { useFantaHockeyAuth } from 'providers/AuthProvider'
import { useDocumentTitle } from '@uidotdev/usehooks'
import { useNavigate } from 'react-router'
import { initializeMsalInstance } from 'features/login/setup/microsoft/microsoftPublicClient'
import { type IPublicClientApplication } from '@azure/msal-browser'
import { OAuthProvider, type OAuthLoginResponse } from 'features/login/models/generic/OAuthResponse'
import { type CredentialResponse, GoogleLogin } from '@react-oauth/google'
import { useSnackbar } from 'hooks/notification/UseSnackbar'
import { Lock } from '@mui/icons-material'
import { useMutation } from '@tanstack/react-query'
import { ApiMutationKeys, ApiMutationService } from 'services/mutation/apiMutationService'

const AppLogin: React.FC = React.memo((): JSX.Element => {
  useDocumentTitle('FantaHockey | Login')
  const navigate = useNavigate()
  const fantaHockeyAuth = useFantaHockeyAuth()
  const [msalInstance, setMsalInstance] = React.useState<IPublicClientApplication>()
  const [isLoading, setLoading] = React.useState(false)
  const { openSnackbar } = useSnackbar()

  const { mutateAsync: loginWithMicrosoftAsync } = useMutation<OAuthLoginResponse, unknown, string>({
    mutationKey: [ApiMutationKeys.LoginWithMicrosoftMutation],
    mutationFn: async (idToken: string) => {
      return (await ApiMutationService[ApiMutationKeys.LoginWithMicrosoftMutation](idToken)).data
    }
  })

  const { mutateAsync: loginWithGoogleAsync } = useMutation<OAuthLoginResponse, unknown, string>({
    mutationKey: [ApiMutationKeys.LoginWithGoogleMutation],
    mutationFn: async (idToken: string) => {
      return (await ApiMutationService[ApiMutationKeys.LoginWithGoogleMutation](idToken)).data
    }
  })

  React.useLayoutEffect(() => {
    if (fantaHockeyAuth.isAuthenticated) {
      navigate('/home', { replace: true })
    }
  }, [fantaHockeyAuth.isAuthenticated])
  React.useEffect(() => {
    const setupMsalLogin = async (): Promise<void> => {
      const instance = await initializeMsalInstance()
      setMsalInstance(instance)
    }

    void setupMsalLogin()
    fantaHockeyAuth.storeMsalInstance(msalInstance)
    if (fantaHockeyAuth.isAuthenticated) {
      navigate('/home', { replace: true })
    }
  }, [])

  const loginMicrosoftCallbackHandler = async (idToken: string): Promise<OAuthLoginResponse> => {
    return await loginWithMicrosoftAsync(idToken)
  }

  const loginGoogleCallbackHandler = async (idToken: string | undefined): Promise<OAuthLoginResponse> => {
    return await loginWithGoogleAsync(idToken ?? '')
  }

  const loginMicrosoftAuthentication = async (): Promise<void> => {
    try {
      setLoading(true)
      const response = await msalInstance?.loginPopup()
      if (response?.account !== undefined) {
        const { idToken } = response
        const oAuthLoginResponse = await loginMicrosoftCallbackHandler(idToken)
        if (oAuthLoginResponse.isAuthenticated) {
          setLoading(false)
          const loginModel = {
            securityToken: idToken ?? '',
            name: oAuthLoginResponse.name,
            isAuthenticated: true,
            email: oAuthLoginResponse.email,
            provider: OAuthProvider.Microsoft
          }
          fantaHockeyAuth.login(loginModel)
          openSnackbar('Logged In', 'success')
        }
      }
      setLoading(false)
    } catch (error: unknown) {
      console.error(error)
      openSnackbar('Login Failed', 'error')
      setLoading(false)
    }
  }

  const googleLoginCallbackHandler = async (credentialResponse: CredentialResponse): Promise<void> => {
    try {
      setLoading(true)
      const oAuthLoginResponse = await loginGoogleCallbackHandler(credentialResponse.credential)
      if (oAuthLoginResponse.isAuthenticated) {
        setLoading(false)
        const loginModel = {
          securityToken: credentialResponse.credential ?? '',
          name: oAuthLoginResponse.name,
          isAuthenticated: true,
          email: oAuthLoginResponse.email,
          provider: OAuthProvider.Google
        }
        fantaHockeyAuth.login(loginModel)
        openSnackbar('Logged In', 'success')
      }
    } catch (error: unknown) {
      setLoading(false)
    }
  }

  const CenterBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center'
  }))

  const LoginText = styled(Typography)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px'
  }))

  if (fantaHockeyAuth.isAuthenticated) {
    return <></>
  }

  const LoginCard: React.FC<{ children: React.ReactNode }> = ({ children }): JSX.Element => {
    return <Box textAlign={'center'} m={3} sx={{ minWidth: '250px', maxWidth: '450px' }}>
      <Card elevation={24}>
        <CardContent sx={{ margin: 4, display: { xs: 'block', sm: 'block', md: 'none' } }}>
          {children}
        </CardContent>
        <CardContent sx={{ margin: 8, display: { sm: 'none', xs: 'none', md: 'block' } }}>
          {children}
        </CardContent>
      </Card>
    </Box>
  }

  const DesktopButtons = (): JSX.Element => {
    return (
      <CenterBox sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
        <Box mt={4} mb={4} />
        <Button onClick={async () => { await loginMicrosoftAuthentication() }} sx={{ padding: 0 }}>
          <img src={'./assets/images/microsoft-login.svg'} alt={'Microsoft Login'} height={40}/>
        </Button>
        <Box mt={4} mb={4} />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <GoogleLogin
            shape='square'
            theme={'filled_black'}
            useOneTap={true}
            logo_alignment='left'
            size='large'
            onSuccess={async credentialResponse => {
              await googleLoginCallbackHandler(credentialResponse)
            }}
            onError={() => {
              openSnackbar('Login Failed', 'error')
            }}
          />
        </Box>
      </CenterBox>
    )
  }

  const MobileButtons = (): JSX.Element => (
    <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
      <Box mt={4} mb={4} />
      <Button onClick={async () => { await loginMicrosoftAuthentication() } }
        sx={{ padding: 0 }}>
        <img src={'./assets/images/microsoft-login.svg'} alt={'Microsoft Login'} height={34} />
      </Button>
      <Box mt={4} mb={4} />
      <CenterBox>
      <Button sx={{ padding: 0 }}>
        <GoogleLogin
          shape='square'
          size='medium'
          theme={'filled_black'}
          useOneTap={true}
          logo_alignment='left'
          onSuccess={async (credentialResponse) => {
            await googleLoginCallbackHandler(credentialResponse)
          } }
          onError={() => {
            openSnackbar('Login Failed', 'error')
          } } />
      </Button>
      </CenterBox>
    </Box>
  )

  return (
    <>
      <Box p={0} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignContent={'center'}>
        <LoginCard>
          <Lock color={'info'} sx={{ fontSize: 36 }} />
          <Typography fontFamily={'Spantaran'} variant="h4" color="text.primary">
            Login
          </Typography>
          <Box my={2} />
          <LoginText color="text.primary">
            Select an authentication provider
          </LoginText>
          <DesktopButtons />
          <MobileButtons />
          <Box mt={4}>
            {isLoading && <CircularProgress color='info' />}
          </Box>
        </LoginCard>
      </Box>
    </>
  )
})

AppLogin.propTypes = {

}

AppLogin.displayName = 'AppLogin'
export default AppLogin

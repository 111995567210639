import React from 'react'
import { useDocumentTitle } from '@uidotdev/usehooks'
import { Box, Card, CardContent, Chip, Grid, styled, Typography } from '@mui/material'
import { type Team, type LiveGameScore } from 'features/games/models/liveGameScoreModels'
import LiveGamesContentLoader from 'components/content-loader/ContentLoader'
import PageBase from 'components/page-title/PageBase'
import { ApiQueryService, ApiQueryKeys } from 'services/query/apiQueryService'
import { useQuery } from '@tanstack/react-query'
import TeamImage from 'components/shared-components/TeamImage'

// Styled Components
const ScoreTypography = styled(Typography)({
  fontSize: '2rem',
  fontWeight: 'bold'
})

const PeriodTypography = styled(Typography)({
  fontSize: 'calc(14px + 0.25vw)',
  fontWeight: 'bold'
})

const GameCard = styled(Card)(({ theme }) => ({
  borderRadius: '14px',
  margin: theme.spacing(3),
  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
  backgroundImage: 'radial-gradient(circle, rgba(63,94,251,0.3) 0%, rgba(252,70,107,0) 40%)'
}))

const IntermissionChip = styled(Chip)({
  backgroundColor: '#907AD6'
})

const intervalTimeMs = 5000
const isGameCancelled = (game: LiveGameScore): boolean => game.gameScheduleState === 'CNCL'

const getPeriod = (game: LiveGameScore): string => {
  if (game.gameState === 'OFF' || game.gameState === 'FINAL') {
    return ''
  } else {
    if (game.period === 0) {
      return 'Pre-Game'
    } else if (game.period === 1) {
      return '1st Period'
    } else if (game.period === 2) {
      return '2nd Period'
    } else if (game.period === 3) {
      return '3rd Period'
    } else {
      return 'Overtime'
    }
  }
}

const getGameClock = (game: LiveGameScore): string => {
  if ((game.clock?.inIntermission) ?? false) {
    return `${game.clock?.timeRemaining}`
  }
  if ((game.clock?.timeRemaining) != null && game.gameState === 'LIVE') {
    return game.clock?.timeRemaining
  }
  if (game.gameState === 'FUT') {
    return game.startTime
  }
  return ''
}

const isGameFinal = (game: LiveGameScore): boolean => game.gameState === 'FINAL' || game.gameState === 'OFF'

// Sub-components
const TeamInfo = ({ team, side }: { team: Team, side: 'Home' | 'Away' }): JSX.Element => (
  <Grid item sx={{ textAlign: 'center', borderRadius: '10px' }} lg={5} xs={5}>
    <Box>
      <Typography p={1} variant="h6" sx={{ fontSize: 'calc(14px + 0.25vw)', color: '#fff' }}>
        {side}
      </Typography>
      <Typography fontWeight={700} variant='subtitle2'>
        {team.abbrev}
      </Typography>
    </Box>
    <Box>
      <TeamImage src={team.logo} alt={team.name.default} height={'calc(1vw + 75px)'} width={'calc(1vw + 75px)'} />
    </Box>
    <Box>
      <Typography p={1} variant="h6" sx={{ fontSize: 'calc(14px + 0.25vw)', color: '#fff', display: { sm: 'none', xs: 'none' } }}>
        {team.name.default}
      </Typography>
      <ScoreTypography sx={{ color: '#fff' }}>{team.score}</ScoreTypography>
      <Typography sx={{ color: '#fff' }}>{`Shots ${team.shotsOnGoal}`}</Typography>
    </Box>
  </Grid>
)

const GamePeriodInfo = ({ game }: { game: LiveGameScore }): JSX.Element => (
  <Grid item sx={{ textAlign: 'center', display: { xl: 'block', xs: 'none' } }} xs={2} lg={2}>
    <Typography variant="h5">{getGameStateChip(game)}</Typography>
    <Box pt={2} />
    <Typography variant="subtitle2" sx={{ opacity: 0.7 }}>
      {isGameFinal(game) ? '' : getPeriod(game)}
    </Typography>
    <Typography variant="subtitle2" m={1}>
      {game.clock?.inIntermission ?? false ? <IntermissionChip label={<Typography variant="caption">Intermission</Typography>} sx={{ backgroundColor: '' }} /> : ''}
    </Typography>
    <PeriodTypography variant="subtitle2" sx={{ opacity: 0.7 }}>
      {getGameClock(game)}
    </PeriodTypography>
    <Typography variant="subtitle1" sx={{ opacity: 0.6 }}>
      {game.venue}
    </Typography>
  </Grid>
)

// Game state handler
const getGameStateChip = (liveGameScore: LiveGameScore): JSX.Element => {
  if (isGameCancelled(liveGameScore)) {
    return <Chip label={<Typography variant="caption">Cancelled</Typography>} sx={{ backgroundColor: 'black' }} />
  }

  switch (liveGameScore.gameState) {
    case 'FUT':
      return <Chip label={<Typography variant="caption">Future</Typography>} sx={{ backgroundColor: 'red' }} />
    case 'LIVE':
    case 'CRIT':
      return <Chip label={<Typography variant="caption">Live</Typography>} sx={{ backgroundColor: 'green' }} />
    case 'FINAL':
    case 'OFF':
      return <Chip label={<Typography variant="caption">Final</Typography>} sx={{ backgroundColor: 'black' }} />
    case 'PRE':
      return <Chip label={<Typography variant="caption">Starting</Typography>} sx={{ backgroundColor: 'blue' }} />
    case 'SUSP':
      return <Chip label={<Typography variant="caption">Suspended</Typography>} sx={{ backgroundColor: 'purple' }} />
    default:
      return <></>
  }
}

const getGameState = (game: LiveGameScore): string => {
  if (game.gameState === 'FUT') {
    return game.startTime
  }
  if (game.gameState === 'LIVE' || game.gameState === 'CRIT') {
    return game.clock?.timeRemaining ?? ''
  }
  return 'Final'
}

const LiveGameScores: React.FC = React.memo(() => {
  useDocumentTitle('FantaHockey | Live Scores')
  const { isFetching, data: liveGamesData } = useQuery<LiveGameScore[]>({
    queryKey: [ApiQueryKeys.GetLiveGameScoresQuery],
    queryFn: async () => {
      const response = await ApiQueryService[ApiQueryKeys.GetLiveGameScoresQuery]()
      return response.data
    },
    initialData: [],
    refetchIntervalInBackground: true,
    refetchInterval: (query) => {
      if (query?.state?.data?.some(g => g.gameState === 'LIVE' || g.gameState === 'CRIT') ?? false) {
        return intervalTimeMs
      } else {
        return 0
      }
    }
  })

  const LiveGamesLoader = (): JSX.Element => (
    <>
      <Grid item xs={12} md={12} lg={4} xl={4}>
        <LiveGamesContentLoader height={125} animation="wave" variant="rectangular" />
      </Grid>
      <Grid item xs={12} md={12} lg={4} xl={4}>
        <LiveGamesContentLoader height={125} animation="wave" variant="rectangular" />
      </Grid>
      <Grid item xs={12} md={12} lg={4} xl={4}>
        <LiveGamesContentLoader height={125} animation="wave" variant="rectangular" />
      </Grid>
    </>
  )

  const getLiveGameBackgroundColor = (game: LiveGameScore): string => {
    if (game.gameState === 'LIVE' || game.gameState === 'CRIT') {
      return 'radial-gradient(circle, rgb(60, 179, 113, 0.3) 0%, rgba(252,70,107,0) 40%)'
    } else if (game.gameState === 'FUT') {
      return 'radial-gradient(circle, rgba(255, 0, 0, 0.4) 0%, rgba(252,70,107,0) 40%)'
    } else if (game.gameState === 'PRE') {
      return 'radial-gradient(circle, rgba(0, 0, 255, 0.3) 0%, rgba(252,70,107,0) 40%)'
    } else {
      return 'radial-gradient(circle, rgba(63,94,251,0.3) 0%, rgba(252,70,107,0) 40%)'
    }
  }

  return (
    <PageBase title='Live Scores'>
      {isFetching && liveGamesData.length === 0
        ? <LiveGamesLoader />
        : null
      }
      {liveGamesData.map((game, index) => (
        <Grid key={index} item xs={12} md={12} lg={4} xl={4}>
          {
            <GameCard
            sx={{
              backgroundImage: getLiveGameBackgroundColor(game)
            }}
            >
              <CardContent>
                <Box sx={{ display: { sm: 'block', xs: 'block', md: 'block', lg: 'none', xl: 'none' } }}>
                  <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item sx={{ textAlign: 'center' }} lg={4} xs={4}>
                      <Typography variant='subtitle2'>{getPeriod(game)}</Typography>
                    </Grid>
                    <Grid item sx={{ textAlign: 'center' }} lg={4} xs={4}>
                      {getGameStateChip(game)}
                    </Grid>
                    <Grid item sx={{ textAlign: 'center' }} lg={4} xs={4}>
                      <Typography variant='subtitle2'>{game.inIntermission ? 'Intermission - ' : ''} {getGameClock(game)}</Typography>
                    </Grid>
                  </Grid>
                  {(getGameState(game) === 'FUT' || getGameState(game) === 'OFF' || getGameState(game) === 'PRE') && (
                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Grid item sx={{ textAlign: 'center' }} lg={5} xs={5}>
                        <Typography>{game.startTime}</Typography>
                      </Grid>
                    </Grid>)}
                </Box>
                <Box margin={2}>
                  <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <TeamInfo team={game.homeTeam} side="Home" />
                    <GamePeriodInfo game={game} />
                    <TeamInfo team={game.awayTeam} side="Away" />
                  </Grid>
                </Box>
              </CardContent>
            </GameCard>}
        </Grid>
      ))}
    </PageBase>
  )
})

LiveGameScores.displayName = 'LiveGameScores'
export default LiveGameScores

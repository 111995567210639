import { type SvgIconProps } from '@mui/material'
import React from 'react'

interface DynamicIconProps {
  Icon: React.ComponentType<SvgIconProps>
  color?:
  'inherit'
  | 'action'
  | 'disabled'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  fontSizeMd?: number
  fontSizeSm?: number
}

const DynamicIcon: React.FC<DynamicIconProps> = (
  {
    Icon,
    color = 'success',
    fontSizeMd = 72,
    fontSizeSm = 48
  }
) => {
  return (
    <>
      <Icon color={color} sx={{ fontSize: fontSizeSm, display: { sm: 'block', xs: 'block', md: 'none' } }} />
      <Icon color={color} sx={{ fontSize: fontSizeMd, display: { sm: 'none', xs: 'none', md: 'block' } }} />
    </>

  )
}

export default DynamicIcon

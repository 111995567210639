import { Box, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

interface Props {
  title: string
}

const AppPageTitle: React.FC<Props> = React.memo(({ title }): JSX.Element => {
  return (
    <Box pl={2} ml={2}>
      <Typography
        fontFamily={'Spantaran'}
        variant='h4'
        sx={{
          display: {
            xl: 'block',
            lg: 'block',
            md: 'none',
            xs: 'none'
          }
        }}
      >
        {title}
      </Typography>
      <Typography
        fontFamily={'Spantaran'}
        variant='h5'
        sx={{
          display: {
            xl: 'none',
            lg: 'none',
            md: 'block',
            xs: 'block'
          }
        }}
      >
        {title}
      </Typography>
    </Box>
  )
})

AppPageTitle.propTypes = {
  title: PropTypes.string.isRequired
}

AppPageTitle.displayName = 'AppPageTitle'
export default AppPageTitle

import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import './AppLoader.scss'

const AppLoader: React.FC = React.memo((): JSX.Element => {
  return (
    <Box textAlign={'center'} mt={'45dvh'}>
      <Box mb={3} />
      <h1>FantaHockey</h1>
      <Box mt={2} />
      <CircularProgress color='info' size={'calc(32px + 1vw)'} thickness={3} />
    </Box>
  )
})

AppLoader.displayName = 'AppLoader'
export default AppLoader

import { Box, Chip } from '@mui/material'
import { TeamImage } from 'components/shared-components/TeamImage'
import React from 'react'
import PropTypes from 'prop-types'

interface Props {
  fantasyScore: number
  minFantasyScore: number
  maxFantasyScore: number
  teamLogoUrl: string
}

export const PlayerFantasyScore: React.FC<Props> = React.memo(({ fantasyScore, maxFantasyScore, teamLogoUrl }): JSX.Element => {
  const formatFantasyScore = (fantasyScore: number): string => {
    return fantasyScore.toFixed(2)
  }

  const getBackgroundColorFromValue = (value: number, min: number, max: number): string => {
    // Ensure value is between min and max
    const normalizedValue = Math.min(Math.max(value, min), max)

    // Calculate ratio based on normalized value (0 = red, 1 = green)
    const ratio = (normalizedValue - min) / (max - min)

    // Interpolate between red and green
    const r = Math.floor(255 * (1 - ratio)) // Red decreases as ratio increases
    const g = Math.floor(255 * ratio) * 0.8 // Green increases as ratio increases
    const b = 0 // Blue stays at 0

    // Convert RGB to HEX
    const hexColor = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`

    return hexColor
  }

  return <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        justifyItems={'center'}>
        <Chip
            label={formatFantasyScore(fantasyScore)}
            sx={{ backgroundColor: getBackgroundColorFromValue(fantasyScore, 0, maxFantasyScore), fontFamily: 'Spantaran' }} />
        <Box mr={1} />
        <TeamImage src={teamLogoUrl} />
    </Box>
})

PlayerFantasyScore.propTypes = {
  fantasyScore: PropTypes.number.isRequired,
  maxFantasyScore: PropTypes.number.isRequired,
  teamLogoUrl: PropTypes.string.isRequired
}

PlayerFantasyScore.displayName = 'PlayerFantasyScore'
export default PlayerFantasyScore

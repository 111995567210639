import React from 'react'
import { Box, Typography, Button, Link, List, ListItem, Paper } from '@mui/material'
import { Container } from '@mui/system'

const YahooFantasyStepsToConnect: React.FC = (): JSX.Element => {
  return (
    <Paper sx={{ display: { md: 'block', sm: 'none', xs: 'none' }, p: 2, m: 2 }} elevation={3}>
      <Container sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          How to Find Your Yahoo Fantasy Hockey League ID
        </Typography>

        {/* Step 1 */}
        <Typography variant="h6" gutterBottom>
          1. Log in to Yahoo Fantasy
        </Typography>
        <Typography variant="body1" paragraph>
          Go to{' '}
          <Link
            href="https://sports.yahoo.com/fantasy"
            target="_blank"
            sx={{ color: 'white' }}
            rel="noopener"
          >
            Yahoo Fantasy Sports
          </Link>{' '}
          and log in with your Yahoo account.
        </Typography>

        {/* Step 2 */}
        <Typography variant="h6" gutterBottom>
          2. Open Your Fantasy Hockey League
        </Typography>
        <Typography variant="body1" paragraph>
          Click on the &quot;Fantasy&quot; tab at the top, then select &quot;Hockey.&quot; Choose your specific league from the list.
        </Typography>

        {/* Step 3 */}
        <Typography variant="h6" gutterBottom>
          3. Check the URL for the League ID
        </Typography>
        <Typography variant="body1" paragraph>
          After you’re on your league’s homepage, look at the web address (URL) in your browser. Your League ID is the series of numbers that comes after <code>/hockey/</code> in the URL.
        </Typography>
        <Typography variant='caption' paragraph width={300}>
          For example:
          <br/>
          <code>https://.../hockey/12345</code>
          <br />
          In this example, the League ID is <strong>12345</strong>.
        </Typography>

        {/* Step 4 */}
        <Typography variant="h6" gutterBottom>
          4. League Settings Page (Alternative Method)
        </Typography>
        <Typography variant="body1" paragraph>
          Alternatively, you can find the League ID in your league’s settings:
        </Typography>
        <List>
          <ListItem>
            - From your league homepage, click on &quot;Commissioner&quot; (if you’re the commissioner) or
            &quot;League Settings.&quot;
          </ListItem>
          <ListItem>
            - The League ID will be listed in the &quot;League Information&quot; section.
          </ListItem>
        </List>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button
            variant="contained"
            color="info"
            href="https://sports.yahoo.com/fantasy"
            target="_blank"
            rel="noopener"
          >
            Yahoo! Fantasy
          </Button>
        </Box>
      </Container>
    </Paper>
  )
}

export default YahooFantasyStepsToConnect

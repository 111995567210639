import React from 'react'

export const ServiceWorkerAppCacheName = 'fantahockey-app'

export const useLocalStorage = (key: string, updatedValue: string): unknown => {
  const [storedValue, setStoredValue] = React.useState<string>(() => {
    try {
      const value = window.localStorage.getItem(key)
      if (value != null) {
        return JSON.parse(value)
      } else {
        window.localStorage.setItem(key, updatedValue)
      }
    } catch (err) {
      return updatedValue
    }
  })

  const setValue = (newValue: string): void => {
    try {
      window.localStorage.setItem(key, newValue)
    } catch (err) {
      console.error(err)
    }
    setStoredValue(newValue)
  }

  return [storedValue, setValue]
}

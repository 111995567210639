/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useReducer } from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import { httpAxiosInstance } from 'services/http/axiosInstance'
import { CircularProgress, Grid, TextField } from '@mui/material'
import { useLocation } from 'react-router'
import { type YahooLeagueTeam } from 'features/fantasy-team/models/yahooFantasyLeagueTeam'
import YahooFantasyTeamSelector from './YahooFantasyTeamSelector'
import YahooFantasyStepsToConnect from './YahooFantasyStepsToConnect'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import { useMutation, useQuery } from '@tanstack/react-query'
import LoadingButton from '@mui/lab/LoadingButton'
import { ApiMutationKeys, ApiMutationService } from 'services/mutation/apiMutationService'

enum Steps {
  CONNECT_YAHOO = 0,
  SELECT_LEAGUE = 1,
  SELECT_TEAM = 2,
}

interface State {
  completed: Record<number, boolean>
  activeStep: number
  selectedTeam: YahooLeagueTeam | null
  fantasyLeagueId: string
  isLoading: boolean
  code: string
  teams: YahooLeagueTeam[]
}

const initialState: State = {
  completed: {} satisfies Record<number, boolean>,
  activeStep: 0,
  code: '',
  selectedTeam: null as YahooLeagueTeam | null,
  fantasyLeagueId: '',
  isLoading: true,
  teams: [] as YahooLeagueTeam[]
}

enum ActionTypes {
  SET_ACTIVE_STEP = 'SET_ACTIVE_STEP',
  MARK_STEP_COMPLETED = 'MARK_STEP_COMPLETED',
  SET_SELECTED_TEAM = 'SET_SELECTED_TEAM',
  SET_FANTASY_LEAGUE_ID = 'SET_FANTASY_LEAGUE_ID',
  SET_LOADING = 'SET_LOADING',
  SET_TEAMS = 'SET_TEAMS',
  SET_CODE = 'SET_CODE'
}

type Action =
  | { type: ActionTypes.SET_ACTIVE_STEP, payload: number }
  | { type: ActionTypes.MARK_STEP_COMPLETED, payload: number }
  | { type: ActionTypes.SET_SELECTED_TEAM, payload: YahooLeagueTeam }
  | { type: ActionTypes.SET_FANTASY_LEAGUE_ID, payload: string }
  | { type: ActionTypes.SET_LOADING, payload: boolean }
  | { type: ActionTypes.SET_TEAMS, payload: YahooLeagueTeam[] }
  | { type: ActionTypes.SET_CODE, payload: string }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SET_CODE:
      return { ...state, code: action.payload }
    case ActionTypes.SET_ACTIVE_STEP:
      return { ...state, activeStep: action.payload }
    case ActionTypes.MARK_STEP_COMPLETED:
      return { ...state, completed: { ...state.completed, [action.payload]: true } }
    case ActionTypes.SET_SELECTED_TEAM:
      return { ...state, selectedTeam: action.payload }
    case ActionTypes.SET_FANTASY_LEAGUE_ID:
      return { ...state, fantasyLeagueId: action.payload }
    case ActionTypes.SET_LOADING:
      return { ...state, isLoading: action.payload }
    case ActionTypes.SET_TEAMS:
      return { ...state, teams: action.payload }
    default:
      return state
  }
}

interface Props {
  onCompletedCallback: () => boolean
}

const ConnectYahooFantasyTeamStepper: React.FC<Props> = ({ onCompletedCallback }): JSX.Element => {
  const location = useLocation()
  const [state, dispatch] = useReducer(reducer, initialState)

  const totalSteps = (): number => steps.length
  const completedSteps = (): number => Object.keys(state.completed).length
  const isLastStep = (): boolean => state.activeStep === totalSteps() - 1
  const allStepsCompleted = (): boolean => completedSteps() === totalSteps()

  const { isFetching: isGettingFantasyToken, refetch: getYahooFantasyToken } = useQuery<boolean>({
    queryKey: [ApiQueryKeys.ConnectToYahooFantasyProfileQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.ConnectToYahooFantasyProfileQuery](state.code)).data
    },
    enabled: false
  })

  const { isFetching: isGettingFantasyTeams, refetch: getYahooFantasyTeams } = useQuery<YahooLeagueTeam[]>({
    queryKey: [ApiQueryKeys.GetYahooFantasyTeamsQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetYahooFantasyTeamsQuery](state.fantasyLeagueId)).data
    },
    enabled: false
  })

  const { isPending, mutateAsync: addYahooFantasyTeam } = useMutation<boolean>({
    mutationKey: [ApiMutationKeys.AddYahooFantasyTeamMutation],
    mutationFn: async () => {
      return (await ApiMutationService[ApiMutationKeys.AddYahooFantasyTeamMutation]({
        teamId: state.selectedTeam?.teamId,
        teamKey: state.selectedTeam?.teamKey
      })).data
    }
  })

  React.useEffect(() => {
    dispatch({ type: ActionTypes.SET_LOADING, payload: isGettingFantasyTeams || isGettingFantasyToken })
  }, [isGettingFantasyTeams, isGettingFantasyToken, isPending, state.isLoading])

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const code = params.get('code')
    if (code !== null) {
      dispatch({ type: ActionTypes.SET_CODE, payload: code })
      const connectToYahooFantasyProfile = async (): Promise<void> => {
        const isTokenStored = await getYahooFantasyToken()
        if (isTokenStored.data === true) {
          dispatch({ type: ActionTypes.SET_ACTIVE_STEP, payload: Steps.SELECT_LEAGUE })
          dispatch({ type: ActionTypes.MARK_STEP_COMPLETED, payload: Steps.CONNECT_YAHOO })
        }
      }
      void connectToYahooFantasyProfile()
    }
  }, [location])

  React.useEffect(() => {
    if (state.activeStep === Steps.SELECT_TEAM && state.fantasyLeagueId !== '') {
      const fetchTeams = async (): Promise<void> => {
        const result = await getYahooFantasyTeams()
        dispatch({ type: ActionTypes.SET_TEAMS, payload: result?.data ?? [] })
        dispatch({ type: ActionTypes.MARK_STEP_COMPLETED, payload: Steps.SELECT_LEAGUE })
      }
      void fetchTeams()
    }
  }, [state.activeStep, state.fantasyLeagueId])

  React.useEffect(() => {
    if (allStepsCompleted() && state.selectedTeam !== null) {
      const saveSelectedTeam = async (): Promise<void> => {
        dispatch({ type: ActionTypes.SET_LOADING, payload: true })
        await addYahooFantasyTeam()
        onCompletedCallback()
        dispatch({ type: ActionTypes.SET_LOADING, payload: false })
      }
      void saveSelectedTeam()
    }
  }, [state.selectedTeam, state.completed])

  const CenterBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  })

  const YahooLogo = styled('img')({
    height: 'calc(0.5vw + 100px)'
  })

  const ConnectText = styled(Typography)({
    fontSize: '16px)'
  })

  const steps = [
    {
      id: Steps.CONNECT_YAHOO,
      label: (
        <Typography component={'span'}>Connect Your Yahoo! Fantasy Account</Typography>
      )
    },
    {
      id: Steps.SELECT_LEAGUE,
      label: <Typography component={'span'}>Select Your League</Typography>
    },
    {
      id: Steps.SELECT_TEAM,
      label: <Typography component={'span'}>Select Your Team</Typography>
    }
  ]

  const syncToYahooFantasy = async (): Promise<void> => {
    try {
      dispatch({ type: ActionTypes.SET_LOADING, payload: true })
      const connectUri = await httpAxiosInstance.get('/yahoo/fantasy/team/authorize-uri')
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      window.open(connectUri.data, '_self')?.focus()
      dispatch({ type: ActionTypes.SET_LOADING, payload: false })
    } catch (error: unknown) {
      console.error('Error:', error)
      dispatch({ type: ActionTypes.SET_LOADING, payload: false })
    }
  }

  const handleNext = (): void => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in state.completed))
        : state.activeStep + 1
    dispatch({ type: ActionTypes.SET_ACTIVE_STEP, payload: newActiveStep })
  }

  const handleBack = (): void => {
    dispatch({ type: ActionTypes.SET_ACTIVE_STEP, payload: state.activeStep - 1 })
  }

  const handleStep = (step: number) => () => {
    dispatch({ type: ActionTypes.SET_ACTIVE_STEP, payload: step })
  }

  const handleComplete = (): void => {
    dispatch({ type: ActionTypes.MARK_STEP_COMPLETED, payload: state.activeStep })
    if (isLastStep()) {
      onCompletedCallback()
    } else {
      handleNext()
    }
  }

  return (
    <Grid item xs={12} md={12} lg={12} xl={12}>
      <Box m={2} p={2}>
        <Box>
          <Stepper
            nonLinear={false}
            orientation="vertical"
            activeStep={state.activeStep}
            sx={{
              bgcolor: 'background.paper',
              p: 2,
              borderRadius: 2,
              boxShadow: 3
            }}
          >
            {steps.map((label, index) => (
              <Step key={label.id} completed={state.completed[index]}>
                <StepButton
                  onClick={handleStep(index)}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignContent: 'center',
                    justifyItems: 'center',
                    '& .MuiStepLabel-label': {
                      fontWeight: state.activeStep === index ? 'bold' : 'normal',
                      fontSize: '1.1rem',
                      color: state.activeStep === index ? '#fff' : 'text.secondary'
                    },
                    '& .MuiStepIcon-root': {
                      transition: 'color 0.3s ease',
                      color: 'info.main'
                    },
                    '& .MuiStepLabel-root .Mui-active': {
                      color: 'green' // circle color (ACTIVE)
                    },
                    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                      fill: 'black' // circle's number (ACTIVE)
                    },
                    '&:hover': {
                      backgroundColor: 'rbga(24, 24, 0, 1)',
                      borderRadius: 1
                    }
                  }}
                >
                  {label.label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <Box
            mt={3}
            sx={{
              p: 0,
              borderRadius: 2,
              bgcolor: 'background.default',
              boxShadow: 2
            }}
          >
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                  {state.activeStep === Steps.CONNECT_YAHOO && (
                    <Box>
                      <CenterBox>
                        <YahooLogo src="./assets/images/Yahoo!-Logo.wine.png" alt={'Yahoo!'} />
                        <ConnectText>Connect your Yahoo! fantasy account</ConnectText>
                        <Box mt={2} />
                        <Button
                          variant="contained"
                          color="success"
                          onClick={async () => {
                            await syncToYahooFantasy()
                          }}
                        >
                          Connect
                        </Button>
                        <Box mt={4}>
                          {state.isLoading && <CircularProgress color="info" />}
                        </Box>
                      </CenterBox>
                    </Box>
                  )}

                  {state.activeStep === Steps.SELECT_LEAGUE && (
                    <>
                      <YahooFantasyStepsToConnect />
                      <Box mt={4} />
                      <Box sx={{ textAlign: 'center' }}>
                        <TextField
                          id="leagueId"
                          label='Yahoo! League Identifier'
                          fullWidth
                          type='text'
                          color='info'
                          variant='filled'
                          value={state.fantasyLeagueId}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            dispatch({ type: ActionTypes.SET_FANTASY_LEAGUE_ID, payload: event.target.value })
                          }}
                        />
                      </Box>
                    </>
                  )}

                  {state.activeStep === Steps.SELECT_TEAM && (
                    <Box>
                      <YahooFantasyTeamSelector
                        teams={state.teams}
                        createSelectedTeam={(team) => {
                          dispatch({ type: ActionTypes.SET_SELECTED_TEAM, payload: team })
                        }}
                      />
                    </Box>
                  )}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2, p: 3 }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={state.activeStep === 0}
                    onClick={handleBack}
                    sx={{ borderRadius: '20px', px: 4 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                {state.activeStep !== steps.length && (
                  <>
                    <LoadingButton
                      color="primary"
                      onClick={handleComplete}
                      loading={state.isLoading}
                      loadingPosition="center"
                      variant="contained"
                    >
                      {state.activeStep === Steps.SELECT_TEAM ? 'Finish' : 'Next'}
                    </LoadingButton>
                  </>
                )}
              </Box>
            </React.Fragment>
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}

ConnectYahooFantasyTeamStepper.displayName = 'ConnectYahooFantasyTeamStepper'
export default ConnectYahooFantasyTeamStepper

import { type Configuration, type IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser'

const initializeMsalInstance = async (): Promise<IPublicClientApplication> => {
  const msalConfig: Configuration = {
    auth: {
      clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID ?? '',
      redirectUri: process.env.REACT_APP_MICROSOFT_REDIRECT_URI ?? ''
    },
    cache: {
      storeAuthStateInCookie: false
    }
  }

  const msalInstance =
        await PublicClientApplication.createPublicClientApplication(msalConfig)

  await msalInstance.initialize()
  return msalInstance
}

export { initializeMsalInstance }

import { type AxiosResponse } from 'axios'
import { type ConnectedYahooFantasyTeam } from 'features/fantasy-team/models/yahooConnectedTeam'
import { type YahooLeagueTeam } from 'features/fantasy-team/models/yahooFantasyLeagueTeam'
import type YahooFantasyPlayerStatistics from 'features/fantasy-team/models/yahooFantasyPlayerStatistics'
import { type LiveGameScore } from 'features/games/models/liveGameScoreModels'
import { type HomeDashboardViewModel } from 'features/home-dashboard/models/homeDashboardModels'
import { type FavoriteNhlTeamProfile, type FavoriteNhlTeamSelection } from 'features/settings/nhlTeamSelection'
import { httpAxiosInstance } from 'services/http/axiosInstance'

export enum ApiQueryKeys {
  GetDashboardHomeQuery = 'GetDashboardHomeQuery',
  IsUserConnectedToYahooQuery = 'IsUserConnectedToYahooQuery',
  GetAllNhlTeamsQuery = 'GetAllNhlTeamsQuery',
  GetUsersFavoriteNhlTeamQuery = 'GetUsersFavoriteNhlTeamQuery',
  ConnectToYahooFantasyProfileQuery = 'ConnectToYahooFantasyProfileQuery',
  GetLiveGameScoresQuery = 'GetLiveGameScoresQuery',
  GetYahooFantasyTeamsQuery = 'GetYahooFantasyTeamsQuery',
  GetConnectedYahooFantasyTeamQuery = 'GetConnectedYahooFantasyTeamQuery',
  GetYahooFantasyPlayerStatistics = 'GetYahooFantasyPlayerStatistics'
}

export type ApiQueryResponse<T> = Promise<AxiosResponse<T>>

type GetDashboardHomeQuery = () => ApiQueryResponse<HomeDashboardViewModel>
type GetAllNhlTeamsQuery = () => ApiQueryResponse<FavoriteNhlTeamSelection[]>
type GetUsersFavoriteNhlTeamQuery = () => ApiQueryResponse<FavoriteNhlTeamProfile>
type IsUserConnectedToYahooQuery = () => ApiQueryResponse<boolean>
type ConnectToYahooFantasyProfileQuery = (code: string) => ApiQueryResponse<boolean>
type GetLiveGameScoresQuery = () => ApiQueryResponse<LiveGameScore[]>
type GetYahooFantasyTeamQuery = (fantasyLeagueId: string) => ApiQueryResponse<YahooLeagueTeam[]>
type GetConnectedYahooFantasyTeamQuery = () => ApiQueryResponse<ConnectedYahooFantasyTeam>
type GetYahooFantasyPlayerStatistics = (playerId: number) => ApiQueryResponse<YahooFantasyPlayerStatistics>

export interface ApiQueryServiceType {
  [ApiQueryKeys.GetDashboardHomeQuery]: GetDashboardHomeQuery
  [ApiQueryKeys.IsUserConnectedToYahooQuery]: IsUserConnectedToYahooQuery
  [ApiQueryKeys.GetAllNhlTeamsQuery]: GetAllNhlTeamsQuery
  [ApiQueryKeys.GetUsersFavoriteNhlTeamQuery]: GetUsersFavoriteNhlTeamQuery
  [ApiQueryKeys.ConnectToYahooFantasyProfileQuery]: ConnectToYahooFantasyProfileQuery
  [ApiQueryKeys.GetLiveGameScoresQuery]: GetLiveGameScoresQuery
  [ApiQueryKeys.GetYahooFantasyTeamsQuery]: GetYahooFantasyTeamQuery
  [ApiQueryKeys.GetConnectedYahooFantasyTeamQuery]: GetConnectedYahooFantasyTeamQuery
  [ApiQueryKeys.GetYahooFantasyPlayerStatistics]: GetYahooFantasyPlayerStatistics
}

export const ApiQueryService: ApiQueryServiceType = {
  [ApiQueryKeys.GetDashboardHomeQuery]: async () => {
    return await httpAxiosInstance.get<HomeDashboardViewModel>('home/dashboard')
  },
  [ApiQueryKeys.IsUserConnectedToYahooQuery]: async () => {
    return await httpAxiosInstance.get<boolean>('yahoo/fantasy/team/has-connected-team')
  },
  [ApiQueryKeys.GetAllNhlTeamsQuery]: async () => {
    return await httpAxiosInstance.get<FavoriteNhlTeamSelection[]>('nhl/teams')
  },
  [ApiQueryKeys.GetUsersFavoriteNhlTeamQuery]: async () => {
    return await httpAxiosInstance.get<FavoriteNhlTeamProfile>('settings/favorite/nhl/team')
  },
  [ApiQueryKeys.ConnectToYahooFantasyProfileQuery]: async (code: string) => {
    return await httpAxiosInstance.get<boolean>(`/yahoo/fantasy/profile/callback?code=${code}`)
  },
  [ApiQueryKeys.GetLiveGameScoresQuery]: async () => {
    return await httpAxiosInstance.get<LiveGameScore[]>('live/games')
  },
  [ApiQueryKeys.GetYahooFantasyTeamsQuery]: async (fantasyLeagueId: string) => {
    return await httpAxiosInstance.get<YahooLeagueTeam[]>(`/yahoo/fantasy/league/${fantasyLeagueId}/teams`)
  },
  [ApiQueryKeys.GetConnectedYahooFantasyTeamQuery]: async () => {
    return await httpAxiosInstance.get<ConnectedYahooFantasyTeam>('/yahoo/fantasy/user/team')
  },
  [ApiQueryKeys.GetYahooFantasyPlayerStatistics]: async (playerId: number) => {
    return await httpAxiosInstance.get<YahooFantasyPlayerStatistics>(`/yahoo/fantasy/player/${playerId}/statistics`)
  }
}

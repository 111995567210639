import { type AxiosResponse } from 'axios'
import { type SelectedYahooFantasyTeamBindingModel } from 'features/fantasy-team/models/yahooFantasyLeagueTeam'
import { type OAuthLoginResponse } from 'features/login/models/generic/OAuthResponse'
import { httpAxiosInstance } from 'services/http/axiosInstance'

export enum ApiMutationKeys {
  AddYahooFantasyTeamMutation = 'AddYahooFantasyTeamMutation',
  DeleteYahooFantasyTeamMutation = 'DeleteYahooFantasyTeamMutation',
  SetFavoriteNhlTeamMutation = 'SetFavoriteNhlTeamMutation',
  LoginWithMicrosoftMutation = 'LoginWithMicrosoftMutation',
  LoginWithGoogleMutation = 'LoginWithGoogleMutation'
}

export type ApiMutationResponse<T> = Promise<AxiosResponse<T>>

type DeleteYahooFantasyTeamMutation = () => ApiMutationResponse<boolean>
type SetFavoriteNhlTeamMutation = (teamId: number) => ApiMutationResponse<boolean>
type LoginWithMicrosoftMutation = (idToken: string) => ApiMutationResponse<OAuthLoginResponse>
type LoginWithGoogleMutation = (idToken: string) => ApiMutationResponse<OAuthLoginResponse>
type AddYahooFantasyTeamMutation = (selectedYahooFantasyTeamBindingModel: SelectedYahooFantasyTeamBindingModel | null) => ApiMutationResponse<boolean>

export interface ApiMutationServiceType {
  [ApiMutationKeys.DeleteYahooFantasyTeamMutation]: DeleteYahooFantasyTeamMutation
  [ApiMutationKeys.SetFavoriteNhlTeamMutation]: SetFavoriteNhlTeamMutation
  [ApiMutationKeys.LoginWithMicrosoftMutation]: LoginWithMicrosoftMutation
  [ApiMutationKeys.LoginWithGoogleMutation]: LoginWithGoogleMutation
  [ApiMutationKeys.AddYahooFantasyTeamMutation]: AddYahooFantasyTeamMutation
}

export const ApiMutationService: ApiMutationServiceType = {
  [ApiMutationKeys.DeleteYahooFantasyTeamMutation]: async () => {
    return await httpAxiosInstance.delete<boolean>('yahoo/fantasy/team/disconnect')
  },
  [ApiMutationKeys.SetFavoriteNhlTeamMutation]: async (teamId: number) => {
    return await httpAxiosInstance.post<boolean>('settings/favorite/nhl/team', teamId)
  },
  [ApiMutationKeys.LoginWithMicrosoftMutation]: async (idToken: string) => {
    return await httpAxiosInstance.post<OAuthLoginResponse>('.auth/login/microsoft/callback', idToken)
  },
  [ApiMutationKeys.LoginWithGoogleMutation]: async (idToken: string) => {
    return await httpAxiosInstance.post<OAuthLoginResponse>('.auth/login/google/callback', idToken)
  },
  [ApiMutationKeys.AddYahooFantasyTeamMutation]: async (selectedYahooFantasyTeamBindingModel: SelectedYahooFantasyTeamBindingModel | null) => {
    return await httpAxiosInstance.post<boolean>('/yahoo/fantasy/team/add', selectedYahooFantasyTeamBindingModel)
  }
}

import { Grid, Box, Typography } from '@mui/material'
import React from 'react'

interface PlayerStatisticGridItemProps {
  statisticTitle: string
  statistic: number | string | undefined
  maxWidth?: string
}

const PlayerStatisticGridItem: React.FC<PlayerStatisticGridItemProps> = ({ statistic, statisticTitle, maxWidth }): JSX.Element => {
  return <>
        <Grid
            item
            sm={4}
            xs={4}
            md={4}
            lg={4}
            xl={4}
            display="flex"
            direction="column"
            alignItems="center"
            justifyContent="center"
            >
            <Box maxWidth={maxWidth} textAlign={'center'}>
                <Typography variant='subtitle2' fontWeight="700">{statisticTitle}</Typography>
                    <Typography>{statistic}</Typography>
                </Box>
            </Grid>
            </>
}

export default React.memo(PlayerStatisticGridItem)

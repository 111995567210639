import { Card, CardHeader, Skeleton } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

interface Props {
  variant: 'text' | 'rectangular' | 'circular'
  animation: 'pulse' | 'wave' | false
  height: number
}

const LiveGamesContentLoader: React.FC<Props> = React.memo(({ animation, variant, height }): JSX.Element => {
  return <Card sx={{ minHeight: height, m: 2 }}>
      <CardHeader
        avatar={''}
        action={null
        }
        title={
          <>
          <Skeleton
            animation={animation}
            height={10}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation={animation}
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
           <Skeleton
            animation={animation}
            variant={variant}
            height={100}
            width="100%"
            style={{ marginBottom: 6 }}
          />
          </>
        }
        subheader={<Skeleton animation={animation} height={10} width="40%" />}
      />
      </Card>
})

LiveGamesContentLoader.propTypes = {
  animation: PropTypes.any.isRequired,
  variant: PropTypes.any.isRequired,
  height: PropTypes.number.isRequired
}

LiveGamesContentLoader.displayName = 'ContentLoader'
export default LiveGamesContentLoader

import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

export interface PlayerImageProps {
  src: string | undefined
  alt?: string
  height?: string | number
  width?: string | number
}

export const PlayerImage: React.FC<PlayerImageProps> = ({ alt, src, height, width }) => {
  return <LazyLoadImage
    alt={alt}
    loading='lazy'
    effect='blur'
    wrapperProps={{
      style: { transitionDelay: '0.125s' }
    }}
    style={
      {
        backgroundColor: '#bdbdbd',
        borderRadius: '50%',
        height: height ?? 'calc(1dvw + 64px)',
        width: width ?? 'calc(1dvw + 64px)'
      }
    }
    placeholderSrc={src}
    src={src}
     />
}

export default React.memo(PlayerImage)

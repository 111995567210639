import React from 'react'
import AppPageTitle from '../app-page-title/AppPageTitle'
import { useDocumentTitle } from '@uidotdev/usehooks'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  type AlertColor,
  AppBar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slide,
  styled,
  Toolbar,
  Typography
} from '@mui/material'
import {
  Cached,
  Close,
  ExpandMore,
  InfoRounded,
  Save,
  SettingsApplications,
  SportsHockeyTwoTone,
  Upgrade
} from '@mui/icons-material'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'
import { type TransitionProps } from '@mui/material/transitions'
import {
  type FavoriteNhlTeamProfile,
  type FavoriteNhlTeamSelection
} from 'features/settings/nhlTeamSelection'
import { useSnackbar } from 'hooks/notification/UseSnackbar'
import { ServiceWorkerAppCacheName } from 'hooks/browser/useLocalStorage'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import { ApiMutationKeys, ApiMutationService } from 'services/mutation/apiMutationService'

const SettingsButton = styled(Button)({
  minWidth: 'calc(100px + 0.25dvw)'
})

const TransitionUp = React.forwardRef(function Transition (
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' exit ref={ref} {...props} />
})

interface SelectFavoriteNhlTeamDialogProps {
  isOpen: boolean
  onClose: () => void
  nhlTeams: FavoriteNhlTeamSelection[]
  currentTeamId: number
  teamUpdated: (teamId: number) => void
  getTeamName: (teamId: number) => string
  openSnackbar: (message: string, severity: AlertColor) => void
}

const SelectFavoriteNhlTeamDialog: React.FC<SelectFavoriteNhlTeamDialogProps> = React.memo(({
  isOpen,
  onClose,
  nhlTeams,
  currentTeamId,
  teamUpdated,
  getTeamName,
  openSnackbar
}) => {
  const [nhlTeamId, setNhlTeamId] = React.useState<number>(currentTeamId)
  const { mutateAsync: saveNhlTeamAsync } = useMutation({
    mutationKey: [ApiMutationKeys.SetFavoriteNhlTeamMutation],
    mutationFn: async () => {
      await ApiMutationService[ApiMutationKeys.SetFavoriteNhlTeamMutation](nhlTeamId)
    }
  })

  const saveNhlTeam = React.useCallback(async () => {
    await saveNhlTeamAsync()
    teamUpdated(nhlTeamId)
    onClose()
    const teamName = getTeamName(nhlTeamId)
    openSnackbar(`Team updated to the ${teamName}`, 'success')
  }, [nhlTeamId, teamUpdated, onClose, getTeamName, openSnackbar])

  React.useEffect(() => {
  }, [nhlTeamId])

  return (
    <Dialog
      keepMounted
      fullScreen
      open={isOpen}
      disableEscapeKeyDown
      onClose={onClose}
      TransitionComponent={TransitionUp}
    >
      <AppBar sx={{ position: 'sticky' }}>
        <Toolbar>
          <IconButton edge='start' color='inherit' onClick={onClose} aria-label='close'>
            <Close />
          </IconButton>
          <Box ml={2} />
          <SportsHockeyTwoTone />
          <Typography sx={{ display: { sm: 'none', xs: 'none', md: 'block' }, ml: 2, flex: 1 }} variant='h6' component='div'>
            Select Your Favorite Team
          </Typography>
          <Typography sx={{ display: { sm: 'block', xs: 'block', md: 'none' }, ml: 2, flex: 1 }} variant='h6' component='div'>
            Select Your Team
          </Typography>
          <SettingsButton startIcon={<Save/>} variant='contained' autoFocus color='success' onClick={() => { void saveNhlTeam() }}>
            Save
          </SettingsButton>
        </Toolbar>
      </AppBar>
      <List>
        {nhlTeams.map((team) => (
          <ListItem
          sx={{
            backgroundColor: team.teamId === nhlTeamId ? 'rgba(0, 0, 0, 0.1)' : 'inherit'
          }}
          key={team.teamId} disablePadding>
            <ListItemButton onClick={() => { setNhlTeamId(team.teamId) }}>
              <ListItemIcon>
                <Checkbox
                  edge='start'
                  color='success'
                  checked={nhlTeamId === team.teamId}
                  tabIndex={-1}
                />
              </ListItemIcon>
              <img src={team.logoUri} alt={team.teamName} height={48} />
              <Box ml={2} />
              <ListItemText primary={team.teamName} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
})

SelectFavoriteNhlTeamDialog.displayName = 'MyFantasyTeam'
SelectFavoriteNhlTeamDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  nhlTeams: PropTypes.array.isRequired,
  currentTeamId: PropTypes.number.isRequired,
  teamUpdated: PropTypes.func.isRequired,
  getTeamName: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired
}

const AppSettings: React.FC = () => {
  useDocumentTitle('FantaHockey | Settings')

  const { openSnackbar } = useSnackbar()
  const [isConnectedToYahoo, setIsConnectedToYahoo] = React.useState<boolean>(false)
  const [nhlTeams, setNhlTeams] = React.useState<FavoriteNhlTeamSelection[]>([])
  const [isNhlDialogOpen, setIsNhlDialogOpen] = React.useState<boolean>(false)
  const [favoriteNhlTeamId, setFavoriteNhlTeamId] = React.useState<number>(0)

  const { isFetching: isYahooConnectedLoading, refetch: getIsYahooConnected } = useQuery<boolean>({
    queryKey: [ApiQueryKeys.IsUserConnectedToYahooQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.IsUserConnectedToYahooQuery]()).data
    },
    enabled: false
  })
  const { isFetching: isGetAllNhlTeamsLoading, refetch: getAllNhlTeams } = useQuery<FavoriteNhlTeamSelection[]>({
    queryKey: [ApiQueryKeys.GetAllNhlTeamsQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetAllNhlTeamsQuery]()).data
    },
    enabled: false
  })
  const { isFetching: favoriteNhlTeamSelectionLoading, refetch: getFavoriteNhlTeamSelection } = useQuery<FavoriteNhlTeamProfile>({
    queryKey: [ApiQueryKeys.GetUsersFavoriteNhlTeamQuery],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetUsersFavoriteNhlTeamQuery]()).data
    },
    enabled: false
  })

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const [isConnectedToYahoo, allNhlTeams, favoriteNhlTeamSelection] = await Promise.all([
        (await getIsYahooConnected()).data,
        (await getAllNhlTeams()).data,
        (await getFavoriteNhlTeamSelection()).data
      ])
      setIsConnectedToYahoo(isConnectedToYahoo ?? false)
      setNhlTeams(allNhlTeams ?? Array.from<FavoriteNhlTeamSelection>([]))
      setFavoriteNhlTeamId(favoriteNhlTeamSelection?.nhlTeamId ?? 0)
    }
    void fetchData()
  }, [])

  const isLoading = isYahooConnectedLoading || isGetAllNhlTeamsLoading || favoriteNhlTeamSelectionLoading
  const SettingsContentLoader = (): JSX.Element => {
    return <>
      <Box textAlign={'center'}>
        <CircularProgress color='info' />
      </Box>
    </>
  }

  const openNhlDialog = (): void => {
    setIsNhlDialogOpen(true)
  }

  const closeNhlDialog = (): void => {
    setIsNhlDialogOpen(false)
  }

  const getTeamName = React.useCallback(
    (teamId: number): string => {
      const team = nhlTeams.find((team) => team.teamId === teamId)
      return (team != null) ? team.teamName : ''
    },
    [nhlTeams]
  )

  const getTeamLogoUri = React.useCallback(
    (teamId: number): string => {
      const team = nhlTeams.find((team) => team.teamId === teamId)
      return (team != null) ? team.logoUri : ''
    },
    [nhlTeams]
  )

  const clearAppServiceWorkerCache = async (): Promise<void> => {
    await caches.delete(ServiceWorkerAppCacheName)
    openSnackbar('Cache cleared', 'info')
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AppPageTitle title='Settings' />
        </Grid>
      </Grid>
      <Box mt={2} />
      {isLoading
        ? <SettingsContentLoader />
        : !isLoading && (
      <Box m={1}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore htmlColor='white' />}
            aria-controls='panel1-content'
            id='panel1-header'
          >
            <IntegrationInstructionsIcon />
            <Box sx={{ ml: 2 }} />
            <Typography>Integrations</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <img src='./assets/images/Yahoo!-Logo.wine.png' alt='yahoo' height={75} />
              {isConnectedToYahoo
                ? (
                <Chip label='Connected' color='success' />
                  )
                : (
                <Chip label='Not Connected' color='error' />
                  )}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore htmlColor='white' />}
            aria-controls='panel2-content'
            id='panel2-header'
          >
            <SettingsApplications />
            <Box sx={{ ml: 2 }} />
            <Typography>Preferences</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={700} >Favorite NHL Team</Typography>
            <Box my={1} />
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography>
                {favoriteNhlTeamId !== 0 && !isLoading
                  ? (
                  <Box display='flex' alignItems='center'>
                     <img
                      src={getTeamLogoUri(favoriteNhlTeamId)}
                      height={30}
                      alt={getTeamName(favoriteNhlTeamId)}
                    />
                    <Box ml={2} />
                    {getTeamName(favoriteNhlTeamId)}
                    <Box ml={2} />
                  </Box>
                    )
                  : (
                      'Unselected'
                    )}
              </Typography>
              <SettingsButton
              onClick={openNhlDialog} variant='contained' color='success' startIcon={ <Upgrade /> } >
                {favoriteNhlTeamId !== 0 ? 'Update' : 'Select'}
              </SettingsButton>
            </Box>
            </AccordionDetails>
            <AccordionDetails>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography fontWeight={700} >Clear App Cache</Typography>
                <Box my={1} />
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <SettingsButton
                  fullWidth={true}
                  startIcon={<Cached />}
                  onClick={() => { void clearAppServiceWorkerCache() }} variant='contained' color='error'>
                    Clear
                  </SettingsButton>
                </Box>
              </Box>
            </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore htmlColor='white' />}
            aria-controls='panel3-content'
            id='panel3-header'
          >
            <InfoRounded />
            <Box sx={{ ml: 2 }} />
            <Typography>Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <strong>FantaHockey</strong> is a cutting-edge web application designed to help
              users create, analyze, and manage their own fantasy hockey teams. The app leverages
              data from external sources, including Yahoo and NHL statistics, to provide a seamless
              and immersive fantasy sports experience.
            </Typography>
            <Box mt={1} />
            <Typography>
              <strong>Disclaimer:</strong> FantaHockey does not own or claim any proprietary rights
              over the data provided by Yahoo, NHL, or any other third-party sources. All external
              data used within the app belongs to their respective owners.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <SelectFavoriteNhlTeamDialog
          isOpen={isNhlDialogOpen}
          onClose={closeNhlDialog}
          nhlTeams={nhlTeams}
          currentTeamId={favoriteNhlTeamId}
          teamUpdated={setFavoriteNhlTeamId}
          getTeamName={getTeamName}
          openSnackbar={openSnackbar}
        />
      </Box>
          )}
    </>
  )
}

export default React.memo(AppSettings)

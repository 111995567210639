import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

export interface PlayerImageProps {
  height?: string
  width?: string
  src: string | undefined
  alt?: string
}

export const TeamImage: React.FC<PlayerImageProps> = ({ alt, src }) => {
  return <LazyLoadImage
    alt={alt}
    loading='lazy'
    effect='blur'
    wrapperProps={{
      style: { transitionDelay: '0.125s' }
    }}
    style={
      {
        height: 'calc(1dvw + 64px)',
        width: 'calc(1dvw + 64px)'
      }
    }
    placeholderSrc={src}
    src={src}
     />
}

export default React.memo(TeamImage)

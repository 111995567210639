import { Grid } from '@mui/material'
import AppPageTitle from 'components/app-page-title/AppPageTitle'
import React from 'react'

interface PageTitleProps {
  title: string
  children: React.ReactNode
}

const PageBase: React.FC<PageTitleProps> = ({ title, children }): JSX.Element => {
  return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <AppPageTitle title={title} />
            </Grid>
            {children}
        </Grid>
  )
}

export default React.memo(PageBase)

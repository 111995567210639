import { Box, Divider, Grid, Paper, styled, SwipeableDrawer, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'
import PropTypes from 'prop-types'
import { type ConnectedYahooFantasyPlayer } from 'features/fantasy-team/models/yahooConnectedTeam'
import { PlayerImage } from 'components/shared-components/PlayerImage'
import type YahooFantasyPlayerStatistics from 'features/fantasy-team/models/yahooFantasyPlayerStatistics'
import { AT, AU, BY, CA, CH, CZ, DE, DK, FI, FR, GB, IT, LV, NO, RU, SE, SI, SV, UA, US } from 'country-flag-icons/react/3x2'
import PlayerStatisticGridItem from 'components/shared-components/PlayerStatisticSection'
import { useQuery } from '@tanstack/react-query'
import { ApiQueryKeys, ApiQueryService } from 'services/query/apiQueryService'
import CircularContentLoader from 'components/circular-content-loader/CircularContentLoader'

interface PlayerStatsSwipeableDrawerProps {
  open: boolean
  height?: number | string
  onOpen: () => void
  player: ConnectedYahooFantasyPlayer
  onClose: () => void
}

const Puller = styled('div')(({ theme }) => ({
  width: 35,
  height: 7,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
  ...theme.applyStyles('dark', {
    backgroundColor: grey[900]
  })
}))

const PlayerStatsSwipeableDrawer: React.FC<PlayerStatsSwipeableDrawerProps> = ({ open, onClose, onOpen, height, player }) => {
  const { isFetching, data: playerStatistics, refetch: getPlayerStatistics } = useQuery<YahooFantasyPlayerStatistics>({
    queryKey: [ApiQueryKeys.GetYahooFantasyPlayerStatistics],
    queryFn: async () => {
      return (await ApiQueryService[ApiQueryKeys.GetYahooFantasyPlayerStatistics](player.playerId)).data
    },
    enabled: !(player.playerId === 0)
  })

  React.useEffect(() => {
    const fetchPlayerStatistics = async (): Promise<void> => {
      if (player.playerId !== 0) {
        await getPlayerStatistics()
      }
    }
    void fetchPlayerStatistics()
  }, [player])

  const mapCountryCodeToFlag = (country: string | undefined): JSX.Element => {
    if (country == null) {
      return <></>
    }
    const flagSize = { width: 36, height: 36 }

    switch (country) {
      case 'CAN':
        return <CA {...flagSize} />
      case 'USA':
        return <US {...flagSize} />
      case 'SWE':
        return <SE {...flagSize} />
      case 'FIN':
        return <FI {...flagSize} />
      case 'RUS':
        return <RU {...flagSize} />
      case 'SVK':
        return <SV {...flagSize} />
      case 'CZE':
        return <CZ {...flagSize} />
      case 'CHE':
        return <CH {...flagSize} />
      case 'DEU':
        return <DE {...flagSize} />
      case 'LAT':
        return <LV {...flagSize} />
      case 'DNK':
        return <DK {...flagSize} />
      case 'AUT':
        return <AT {...flagSize} />
      case 'BLR':
        return <BY {...flagSize} />
      case 'NOR':
        return <NO {...flagSize} />
      case 'UKR':
        return <UA {...flagSize} />
      case 'FRA':
        return <FR {...flagSize} />
      case 'GBR':
        return <GB {...flagSize} />
      case 'AUS':
        return <AU {...flagSize} />
      case 'ITA':
        return <IT {...flagSize} />
      case 'SVN':
        return <SI {...flagSize} />
      default:
        return <></>
    }
  }

  const mapPositionCodeToPosition = (position: string | undefined): string => {
    if (position == null) {
      return ''
    }
    switch (position) {
      case 'C':
        return 'Center'
      case 'L':
        return 'Left Wing'
      case 'R':
        return 'Right Wing'
      case 'D':
        return 'Defense'
      case 'G':
        return 'Goalie'
      default:
        return ''
    }
  }

  return <SwipeableDrawer
    anchor="bottom"
    open={open}
    onClose={onClose}
    onOpen={onOpen}
    disableBackdropTransition={true}
    disableDiscovery={true}
    PaperProps={{
      sx: {
        height: height ?? '90dvh',
        overflow: 'auto'
      }
    }}
    disableSwipeToOpen={false}
    ModalProps={{
      keepMounted: false
    }}
  >
    <Box height={16} width={4}>
      <Puller onClick={onClose} />
    </Box>
    {isFetching
      ? <CircularContentLoader size={'36px'} thickness={4} marginTop={'64px'} textAlignment={'center'} />
      : <Box
      sx={{
        position: 'relative', // Set position to relative for the parent Box
        px: 2,
        pb: 2,
        height: '100%',
        overflow: 'auto'
      }}
    >
      {/* Blurred Background Image */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundPositionX: 'center',
          backgroundPositionY: 'center',
          backgroundSize: '75% 75%',
          backgroundImage: `url(${player?.teamLogoUrl})`,
          backgroundBlendMode: 'lighten',
          backgroundRepeat: 'no-repeat',
          zIndex: -1
        }}
      />
      {/* Content */}
      <Paper sx={{ boxShadow: 'none', mt: 6 }}>
      <Box>
  {/* Player Info Section */}
  <Grid
    container
    p={2}
    spacing={3} // Increase spacing between grid items
    direction="row"
    alignItems="flex-start" // Align items at the start of each column
    justifyContent="space-evenly" // Spread out content evenly across the container
  >
    {/* Left Column */}
    <Grid
      item
      sm={5}
      xs={5}
      md={5}
      lg={6}
      xl={6}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-evenly" // Even spacing between items
      gap={2} // Add vertical spacing between elements in this column
    >
      <Typography>{mapCountryCodeToFlag(playerStatistics?.birthCountry)}</Typography>
      <PlayerImage
        height="calc(1dvw + 96px)"
        width="calc(1dvw + 96px)"
        src={player?.playerHeadshotUrl}
      />
    </Grid>

    {/* Right Column */}
    <Grid
      item
      sm={7}
      xs={7}
      md={7}
      lg={6}
      xl={6}
      display="flex"
      flexDirection="column"
    >
      <Grid container direction="row" spacing={1}>
        {/* Player Number and Name */}
        <Grid item container alignItems="center">
          <Typography mr={1}># {playerStatistics?.sweaterNumber}</Typography>
          <Typography variant="body1" fontWeight="700">
            {player?.name}
          </Typography>
        </Grid>

        {/* Position */}
        <Grid item container alignItems="center">
        <Typography mr={1} fontWeight={700}>Position:</Typography><Typography>{mapPositionCodeToPosition(playerStatistics?.position)}</Typography>
        </Grid>

        {/* Age */}
        <Grid item container alignItems="center">
        <Typography mr={1} fontWeight={700}>Age:</Typography><Typography>{playerStatistics?.age}</Typography>
        </Grid>

        {/* Height */}
        <Grid item container alignItems="center">
        <Typography mr={1} fontWeight={700}>Height:</Typography><Typography>{playerStatistics?.heightInFeetAndInches}</Typography>
        </Grid>

        {/* Weight */}
        <Grid item container alignItems="center">
        <Typography mr={1} fontWeight={700}>Weight:</Typography><Typography>{playerStatistics?.weightInPounds} lbs</Typography>
        </Grid>
    </Grid>
  </Grid>
    </Grid>

          {/* Core Stats Section */}
          <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
            <PlayerStatisticGridItem statistic={playerStatistics?.goals} statisticTitle="Goals" maxWidth='50px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.assists} statisticTitle="Assists" maxWidth='50px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.points} statisticTitle="Points" maxWidth='50px' />
          </Grid>
          <Divider />
           {/* Core Stats Section */}
           <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
            <PlayerStatisticGridItem statistic={playerStatistics?.shots} statisticTitle="Shots" maxWidth='50px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.plusMinus} statisticTitle="+/-" maxWidth='50px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.penaltyMinutes} statisticTitle="PIM" maxWidth='50px' />
          </Grid>
          <Divider />
           {/* Core Stats Section */}
           <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
            <PlayerStatisticGridItem statistic={playerStatistics?.faceoffWinPercentage} statisticTitle="FO %" maxWidth='50px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.shootingPercentage} statisticTitle="SH %" maxWidth='50px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.gameWinningGoals} statisticTitle="GWG" maxWidth='50px' />
          </Grid>
          <Divider />
           {/* Core Stats Section */}
           <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
            <PlayerStatisticGridItem statistic={playerStatistics?.firstGoals} statisticTitle="First Goals" maxWidth='50px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.giveaways} statisticTitle="Give Aways" maxWidth='50px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.hits} statisticTitle="Hits" maxWidth='50px' />
          </Grid>
          <Divider />
          {/* Core Stats Section */}
          <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
            <PlayerStatisticGridItem statistic={playerStatistics?.individualFenwickFor} statisticTitle="IFF" maxWidth='50px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.individualCorsiFor} statisticTitle="ICF" maxWidth='50px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.powerPlayGoals} statisticTitle="PP Goals" maxWidth='80px' />
          </Grid>
          {/* Core Stats Section */}
          <Divider />
          <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
            <PlayerStatisticGridItem statistic={playerStatistics?.overtimeGoals} statisticTitle="OT Goals" maxWidth='50px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.takeaways} statisticTitle="Take Aways" maxWidth='50px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.gamesPlayed} statisticTitle="Games Played" maxWidth='50px' />
          </Grid>
          <Divider />
          <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
            <PlayerStatisticGridItem statistic={playerStatistics?.goalsPer60?.toFixed(2)} statisticTitle="Goals Per 60 min" maxWidth='50px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.assistsPer60?.toFixed(2)} statisticTitle="Assists Per 60 min" maxWidth='50px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.pointsPer60?.toFixed(2)} statisticTitle="Points Per 60 min" maxWidth='50px' />
          </Grid>
          <Divider />
          <Grid container p={2} spacing={2} direction="row" alignItems="center" justifyContent="center">
            <PlayerStatisticGridItem statistic={playerStatistics?.blockedShots} statisticTitle="Total Blocked Shots" maxWidth='50px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.blockedShotsPer60} statisticTitle="Blocked Shots Per 60 Min" maxWidth='75px' />
            <PlayerStatisticGridItem statistic={playerStatistics?.shotAttemptsBlocked} statisticTitle="Shot Attempts Blocked" maxWidth='50px' />
          </Grid>
        </Box>
      </Paper>
    </Box>
    }
  </SwipeableDrawer>
}

PlayerStatsSwipeableDrawer.displayName = 'PlayerStatsSwipeableDrawer'
PlayerStatsSwipeableDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default React.memo(PlayerStatsSwipeableDrawer)

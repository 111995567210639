import React from 'react'
import AppLoader from './components/app-loader/AppLoader'
import AppHeader from 'components/app-header/AppHeader'
import { Box } from '@mui/material'
import { Outlet } from 'react-router'
import AppFooter from 'components/app-footer/AppFooter'
import { useFantaHockeyAuth as useFantaHockeyContext } from 'providers/AuthProvider'
import { setupInterceptors } from 'services/http/axiosInstance'

const App = (): JSX.Element => {
  const fantaHockeyAuth = useFantaHockeyContext()
  setupInterceptors()
  React.useEffect(() => {
    setTimeout(() => {
      fantaHockeyAuth.setSplashScreen(false)
    }, 750)
  }, [])

  if (fantaHockeyAuth.isSplashScreenVisible) {
    return <AppLoader />
  }

  return (
    <>
      <Box pb={4}>
        <AppHeader />
      </Box>
        <Outlet />
      {!fantaHockeyAuth.isAuthenticated ? <AppFooter /> : <></>}
    </>
  )
}

export default App
